import React from 'react'
import { Button } from '../../ButtonElements'
import Bounce from 'react-reveal/Zoom';


import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    Subtitle1,
    BtnWrap,
    Img,
    ImgWrap
} from './InfoElements';
import Slide from 'react-reveal/Slide';


const InfoSection = ({
    lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headLine,
    darkText,
    description,
    description1,
    description2,
    description3,
    description4,
    description5,    
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,
    lightTop
}) => {
  return (
   <>
   <InfoContainer lightBg = {lightBg} id={id}>
    <InfoWrapper>
        <InfoRow imgStart = {imgStart}>
            <Column1>
                <TextWrapper>
                    <TopLine lightTop={lightTop}>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headLine}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                    <Subtitle darkText={darkText}>{description1}</Subtitle>
                    <Subtitle darkText={darkText}>{description2}</Subtitle>
                    <Subtitle darkText={darkText}>{description3}</Subtitle>
                    <Subtitle1 darkText={darkText}>{description5}</Subtitle1>
                    <Subtitle darkText={darkText}>{description4}</Subtitle>

                    <BtnWrap>
                    <Button to='aboutus' smooth={true} duration={500} spy={true} exact='true' offset={-80} primary={true} dark={true}>{buttonLabel}</Button>
                    </BtnWrap>
                </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                <Slide left >
                      <Img src={img} alt={alt} loading='lazy'/> </Slide>
                </ImgWrap>
            </Column2>
        </InfoRow>
    </InfoWrapper>
   </InfoContainer>
   </>
  )
}

export default InfoSection
