import React, {useState} from 'react';

import ReactPlayer from 'react-player/youtube'

import { HeroContainer, 
  HeroBg, 

} from './video';

const VideoSection = () => {

  const [hover, setHover] = useState(false)
  const [playing, setPlaying] = useState(false);

  const handleEnterViewport = function () {
    setPlaying(true);
  };
  const handleExitViewport = function () {
    setPlaying(false);
  };
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
      <ReactPlayer
         
         url='https://youtu.be/83o49pVdogo'
         width='100%'
         height='100%'
         playing={playing}
         playsInline={true}

         fallback={<p>Loading</p>}
         config={{
           youtube: {
             playerVars: { origin: 'https://www.youtube.com' },
           },
         }}
         on
       />
      </HeroBg>
     
  
    </HeroContainer>
  )
}

export default VideoSection
