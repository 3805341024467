import React, {useState} from 'react';
import ReactPlayer from 'react-player/youtube'

import { HeroContainer, 

} from './video';

const VideoSection = () => {
  const [hover, setHover] = useState(false)
  const [playing, setPlaying] = useState(false);

  const handleEnterViewport = function () {
    setPlaying(true);
  };
  const handleExitViewport = function () {
    setPlaying(false);
  };
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
     <ReactPlayer
         
          url='https://youtu.be/04ZK5CC-ZYU'
          width='60%'
          height='60%'
          playing={playing}
          playsInline={true}

          fallback={<p>Loading</p>}
          config={{
            youtube: {
              playerVars: { origin: 'https://www.youtube.com' },
            },
          }}
          on
        />
    </HeroContainer>
  )
}

export default VideoSection
