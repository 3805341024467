import styled from 'styled-components'

export const ServicesContainer = styled.div`
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    

    @media screen and (max-width: 760px){
        height: 100%;
    }

    @media screen and (max-width: 480px){
        height: 100%;
    }
`

export const ServicesWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 60px;
    padding: 0 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const ServicesCard = styled.div`

    background: #fff;
    background-image:linear-gradient(to bottom, #fcebeb,white,white);
    background-color: rgba(0,0,0,0.01);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    max-height: 600px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`
export const ServicesCard1 = styled.div`

    background: #fff;
    background-color: rgba(0,0,0,0.01);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    max-height: 600px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const ServicesH1 = styled.div`
    font-size: 2.5rem;
    color: black;
    margin-bottom: 64px;
    margin-top: 40px;
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const ServicesH2 = styled.div`
    font-size: 30px;
    line-height: 1.5em;
    margin-bottom: 5px;
    color: black;
    display: block;
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;
`

export const ServicesP = styled.p`
    font-size: 1.3rem;
    text-align: center;
    padding: 7px;
    color: black;
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;

    
`

