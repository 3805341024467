import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/PackageComponents/HeroSection'
import Services from '../components/PackageComponents/Services';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Icon1 from '../images/logo-1.jpeg';
import ScrollToTop from '../components/ScrollToTop';
import Icon2 from '../images/stories1/ph1.jpg';
import Icon3 from '../images/stories2/ph2.jpg';
import Icon4 from '../images/stories2/ph3.jpg';
import Icon5 from '../images/stories2/ph4.jpg';
import Icon6 from '../images/stories3/ph5.jpg';
import Icon7 from '../images/stories3/ph6.jpg';
import Icon8 from '../images/stories2/ph7.jpg';
import ResponsiveGallery from 'react-responsive-gallery';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
  const images=[
   
   
  
    {
      src: Icon3
      },
    {
      src: Icon5
      },
      {
        src: Icon4
        },
         
    {
     src: Icon2
      },

  {
    src: Icon6
    },
  {
    src: Icon8
    },
    {
      src: Icon7
      },
  
    ];
  
const PackagesPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
             <Sidebar isOpen={isOpen} toggle={toggle}/>
             <ScrollToTop />
      <Navbar toggle = {toggle}/>
      <HeroSection/>
      <FloatingWhatsApp phoneNumber={'9845274189'} avatar={Icon1} statusMessage={'Online'} chatMessage={'Hey!! Welcome..Send a Message for Any Booking or query'} accountName={'R K PHOTO FLASH'}/>
      <Services />
<ResponsiveGallery images={images} />

      <Footer/>
    </>
  )
}

export default PackagesPage
