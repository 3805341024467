import React from 'react'
import Icon1 from '../../../images/stories3/ph1.jpg';
import Icon2 from '../../../images/stories2/ph4.jpg';
import Icon3 from '../../../images/stories1/ph16.jpg';

import {
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesP
} from './ServicesElements';
import Zoom from 'react-reveal/Zoom';

const Services = () => {
  return (
    <ServicesContainer id="services" >
        <ServicesH1>What we do </ServicesH1>
        <ServicesWrapper>
            <Zoom top duration={700}>
     
            
            <ServicesCard>
                <ServicesIcon src={Icon3} loading='lazy'/>
                <ServicesH2>Wedding</ServicesH2>
                <ServicesP></ServicesP>
            </ServicesCard>
            <ServicesCard to='about'>
                <ServicesIcon src={Icon2} loading='lazy'/>
                <ServicesH2>Engagement</ServicesH2>
                <ServicesP></ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon1} loading='lazy'/>
                <ServicesH2>LifeStyle</ServicesH2>
                <ServicesP></ServicesP>
            </ServicesCard>
            </Zoom>
        </ServicesWrapper>
      
    </ServicesContainer>
  )
}

export default Services
