//orange #f57b1d
//dark blue #05051f
//light blue #34399e



export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    topLine: 'ABOUT US',
    headLine: 'Since 1988',
    description: "I find myself extremly fortunate for choosing my passion as my career.I enjoy every bit of the work I do. I consider everyone as my family and I try to capture the best version of them. Smiles are the most precious gift, and I am glad to capture them. Photography has been a part and parcel of my life. I will never stop capturing y'all.",
    buttonLabel: 'All about us',
    description5: 'R K Prakash',
    description4: 'Founder',
    imgStart: false,
    img: require('../../../images/rk.jpg'),
    alt: 'baby',
    dark: false,
    primary: false
   
    
}





/*export const homeObjFour = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    darkText: true,
    lightTextDesc: false,
    lightTop: true,
    topLine: 'SIGN UP',
    headLine: 'Contact Us',
    description: 'Get in tuch with our professionals to start your investment journey',
    buttonLabel: 'Sign Up',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'Car',
    dark: false,
    primary: false
    
}*/