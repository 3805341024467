import { Form } from 'react-router-dom'
import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';


//orange #f57b1d
//dark blue #05051f
//light blue #34399e

export const HeroContainer = styled.div`
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 600px;
    position: relative;
    z-index: 1;

    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
         background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);

        z-index: 2;

    }
    
`

export const HeroBg = styled.div`
    background: url('/images/photo.jpg')
  ;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    overflow: hidden;
`
export const ImgWrap = styled.div`
    max-width: 100%;
    height: 100%;
`

export const Img = styled.img`
        width: 100%;
        padding-right: 0;
      
`


export const HeroContent = styled.div`
    z-index: 3;
    max-width: 100%;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroP = styled.p`
    id: box;

    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 1000px;

@media screen and (max-width: 768px){
    font-size: 15px;
}

@media screen and (max-width: 480px){
    font-size: 10px;
}
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`
  
export const HeroH1 = styled.div` 

font-size: 80px;

position: relative;

color: whitesmoke;
font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;



    @media screen and (max-width: 768px){
      font-size: 44px;

  }
  
  @media screen and (max-width: 500px){
      font-size: 24px;

  }
  `
  export const LogoIcon = styled.img`
  height: 40px;
  width: 40px;
  top: 230px;

  @media screen and (max-width: 768px){
    height: 40px;
    width: 40px;
  }
  
  @media screen and (max-width: 500px){
    height: 20px;
  width: 20px;
  }

`
