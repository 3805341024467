import React, {useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa';
import Icon1 from '../../images/logo-1.png';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink,
    LogoIcon
}from './NavbarElements';

const Navbar = ({ toggle }) => {

  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = ()=> {
    if(window.scrollY >= 130){
      setScrollNav(true)
    }
    else
    setScrollNav(false)
  }

  useEffect(() => {
    window.addEventListener('scroll',changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <>
    <IconContext.Provider value={{color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
  
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}> RK PHOTO FLASH </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
          <NavItem>
                <NavLinks to="/" 
                smooth={true} duration={500} spy={true} exact='true' 
                offset={-80} >
                Home</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to="/aboutus" 
                smooth={true} duration={500} spy={true} exact='true' 
                offset={-80} >
                About Us</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to="/stories"
                smooth={true} duration={500} spy={true} exact='true' 
                offset={-80}>Stories</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to="/packages"
                smooth={true} duration={500} spy={true} exact='true' 
                offset={-80}>Packages</NavLinks>
            </NavItem>
     
            <NavItem>
                <NavLinks to="/reachout"
                smooth={true} duration={500} spy={true} exact='true' 
                offset={-80}>Reach Out</NavLinks>
            </NavItem>
          </NavMenu>
       
        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
