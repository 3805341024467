import React, {useState} from 'react';
import img from '../../../images/stories2/ph6.jpg';


import { HeroContainer, 
  HeroBg, 
  ImgWrap,
  Img,

  HeroContent,
  HeroH1,
  HeroP,

} from './HeroElements';

const HeroSection = () => {

  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }


  setTimeout(() => {
  
  }, 2000); 

  return (
    <HeroContainer id='home'>
      <HeroBg>
      <ImgWrap>
          <Img src={img} loading='lazy' />
            </ImgWrap>
      </HeroBg>
      <HeroContent>
        <HeroH1>R K Photo Flash</HeroH1>
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
