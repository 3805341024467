//orange #f57b1d
//dark blue #05051f
//light blue #34399e



export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    topLine: 'Reach out to us',
    headLine: 'Contact Us',
    headLine1: 'Office Address',

    description: 'Email: rkprakash1234@gmail.com , rajeshwari12321@gmail.com',
    description1: ' Phone/Whats App: +91 9845274189 , +91 9901031033',
    description2: 'We cover all over the global.Please feel free to reach out to us as we would love to make memories along with you',
    description3: '372/1,80ft Main Road ,M.K.Puttalingaiah Road, Padmanabhanagar, next to Yogananda hospital, Bengaluru, Karnataka 560070. ',
    description4: '#15,32nd cross ,2nd Main Road,Jayanagar 7th Block, opp of Bangalore International Academy (BIGI), Bengaluru, Karnataka 560082',
    imgStart: false ,
    img: require('../../../images/logo-1.png'),
    alt: 'baby',
    dark: false,
    primary: false
   
    
}




/*export const homeObjFour = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    darkText: true,
    lightTextDesc: false,
    lightTop: true,
    topLine: 'SIGN UP',
    headLine: 'Contact Us',
    description: 'Get in tuch with our professionals to start your investment journey',
    buttonLabel: 'Sign Up',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'Car',
    dark: false,
    primary: false
    
}*/