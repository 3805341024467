import React from 'react'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SideBtnWrap,
    SidebarLink,
    SidebarRoute,
    SidebarWrapper,
    SidebarMenu
 }from './SidebarElements'

const Sidebar = ({toggle,isOpen}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
            <SidebarLink to="/" onClick={toggle}>Home</SidebarLink>
            <SidebarLink to="/aboutus" onClick={toggle}>About Us</SidebarLink>
            <SidebarLink to="/stories" onClick={toggle}>Stories</SidebarLink>
            <SidebarLink to="/packages" onClick={toggle}>Packages</SidebarLink>
            <SidebarLink to="/reachout" onClick={toggle}>Reach Out</SidebarLink>



        </SidebarMenu>
        
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
