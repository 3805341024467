import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/Homecomponents/HeroSection'
import InfoSection from '../components/Homecomponents/InfoSection'
import Services from '../components/Homecomponents/Services';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Icon1 from '../images/logo-1.jpeg';
import VideoSection from '../components/Homecomponents/FrontVideo';
import Footer from '../components/Footer';
import { homeObjOne, homeObjTwo, 
} from '../components/Homecomponents/InfoSection/Data';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
         <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle = {toggle}/>

      <HeroSection/>
      <FloatingWhatsApp phoneNumber={'9845274189'} avatar={Icon1} statusMessage={'Online'} chatMessage={'Hey!! Welcome..Send a Message for Any Booking or query'} accountName={'R K PHOTO FLASH'}/>
      <InfoSection {...homeObjOne}/>
      <Services />
      <VideoSection/>
      <InfoSection {...homeObjTwo}/>
      <Footer/>


      
    </>
  )
}

export default Home
