import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import InfoSection from '../components/Storiescomponents/InfoSection'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Icon1 from '../images/logo-1.jpeg';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';

import { homeObjOne, homeObjTwo, 
  homeObjThree ,
} from '../components/Storiescomponents/InfoSection/Data';
import HeroSection from '../components/Storiescomponents/HeroSection'

const StoriesPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <ScrollToTop />
      <Navbar toggle = {toggle}/>
      <HeroSection/>
      <FloatingWhatsApp phoneNumber={'9845274189'} avatar={Icon1} statusMessage={'Online'} chatMessage={'Hey!! Welcome..Send a Message for Any Booking or query'} accountName={'R K PHOTO FLASH'}/>
      <InfoSection {...homeObjOne}/>
      <InfoSection {...homeObjTwo}/>

      <InfoSection {...homeObjThree}/>

      <Footer/>


      
    </>
  )
}

export default StoriesPage
