import styled from 'styled-components'
import {Link} from 'react-scroll'

export const Button = styled(Link)
`
    border-radius: 5px;
    background: ${({primary}) => (primary ? '#ccf8ff' : '##ccf8ff')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? 'black' : 'black')};
    font-size: ${({fontBig}) => (fontBig ? '25px' : '25px')};
    outline: none;
    border: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    width:200px;
    height:50px;
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#ccf8ff')};
        color: ${({dark}) => (dark ? '#ccf8ff' : '#fff')};
    }
`