//orange #f57b1d
//dark blue #05051f
//light blue #34399e



export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    headLine: 'Spoorti Weds Kishore',
    buttonLabel: 'View Album',
    description5: 'Beautiful Engagement | Wedding',
    imgStart: false,
    img: require('../../../images/stories1/ph2.jpg'),
    img1: require('../../../images/stories1/ph3.jpg'),
    alt: 'baby',
    to: "/stories1",
    dark: false,
    primary: false
   
    
}
export const homeObjTwo = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    headLine: 'Rachitha & Rakshith',
    buttonLabel: 'View Pictures',
    description5: 'Wedding | Reception',
    imgStart: true,
    to: "/stories2",
    img: require('../../../images/stories2/ph1.jpg'),
    img1: require('../../../images/stories2/ph5.jpg'),
    alt: 'baby',
    dark: false,
    primary: false,
    
}
export const homeObjThree = {
    id: 'about',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    headLine: 'Nagendra Babu & Preeti ',
    buttonLabel: 'View Photos',
    description5: 'Pre-Wedding Photoshoot | Wedding',
    imgStart: false,
    to: "/stories3",
    img: require('../../../images/stories3/ph4.jpg'),
    img1: require('../../../images/stories3/ph8.jpg'),
    alt: 'baby',
    dark: false,
    primary: false
       
}


