//orange #f57b1d
//dark blue #05051f
//light blue #34399e



export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    topLine: 'WHAT WE DO',
    headLine: 'About us',
    description: "Life gives us a lot of special and priceless moments which can't be relived, but the moments captured can always make us revisit those memories. We would love to document every spontaneous moment of yours in an editorial style. You're precious smiles will be captured :)",
    buttonLabel: 'All about us',
    description5: 'R K Prakash',
    description4: 'Founder & Artist',
    imgStart: false,
    img: require('../../../images/stories1/ph15.jpg'),
    alt: 'baby',
    to:'/aboutus',
    dark: false,
    primary: false
   
    
}
export const homeObjTwo = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    topLine: 'EVERY MOMENT IS SPECIAL',
    headLine: 'The Full Life Experience',

    description: '• Pre Wedding Photo Shoot',
    description1: '• Wedding',
    description2: '• LifeStyle',
    description3: '• Events(School,IT and many more)',
    description4: '• Products and many more..',
    buttonLabel: 'Our Smiles',
    imgStart: false ,
    img: require('../../../images/stories3/ph3.jpg'),
    to:'/stories', 
    dark: false,
    primary: false
   
    
}




/*export const homeObjFour = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    darkText: true,
    lightTextDesc: false,
    lightTop: true,
    topLine: 'SIGN UP',
    headLine: 'Contact Us',
    description: 'Get in tuch with our professionals to start your investment journey',
    buttonLabel: 'Sign Up',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'Car',
    dark: false,
    primary: false
    
}*/