import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
} from './FooterElements';

import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube} from 'react-icons/fa'

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
      }
  return (
    <FooterContainer>
        <FooterWrap>
            
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>RK PHOTO FLASH</SocialLogo>
                   
                    <SocialIcons>
                        <SocialIconLink href="https://www.facebook.com/profile.php?id=100093045580768" target="_blank" 
                        aria-label="Facebook"><FaFacebook/>
                        </SocialIconLink>
                        <SocialIconLink href="//www.instagram.com/rkphotoflash/" target="_blank" 
                        aria-label="Instagram"><FaInstagram/>
                        </SocialIconLink>
                        <SocialIconLink href="https://www.youtube.com/channel/UC3O314PkqyoeuI1DODk_g_g" target="_blank" 
                        aria-label="Youtube"><FaYoutube/>
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
