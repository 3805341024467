import React, {useState} from 'react';
import img from '../../../images/stories3/ph18.jpg';
import { HeroContainer, 
  HeroBg, 
  ImgWrap,
  Img,
  HeroContent,
  HeroH1,
  HeroP

} from './HeroElements';
import Bounce from 'react-reveal/Bounce';

const HeroSection = () => {

  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }


  setTimeout(() => {
  
  }, 2000); 

  return (
    <HeroContainer id='home'>
      <HeroBg>
      <ImgWrap>
          <Img src={img} loading='lazy'/>
            </ImgWrap>
      </HeroBg>
      <HeroContent>
        <HeroH1>Packages</HeroH1>
        <HeroP>To Craft Your Memorable Moments</HeroP>
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
