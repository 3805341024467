import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages';

import AboutusPage from './pages/aboutus';
import PackagesPage from './pages/packages';
import StoriesPage from './pages/stories';
import ReachoutPage from './pages/reachout';
import StoriesPage1 from './pages/stories1';
import StoriesPage2 from './pages/stories2';
import StoriesPage3 from './pages/stories3';






function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />

        <Route path="/aboutus" element={<AboutusPage/>}  />
        <Route path="/packages" element={<PackagesPage/>}  />
        <Route path="/stories" element={<StoriesPage/>}  />
        <Route path="/stories1" element={<StoriesPage1/>}  />
        <Route path="/stories2" element={<StoriesPage2/>}  />
        <Route path="/stories3" element={<StoriesPage3/>}  />

        <Route path="/reachout" element={<ReachoutPage/>}  />
      </Routes>
    </Router>
  );
}

export default App;
