import React, {useState} from 'react';
import img from '../../../images/stories1/ph1.jpg';



import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import { HeroContainer, 
  HeroBg, 
  ImgWrap,
  Img,
  LogoIcon,
  VideoBg,
  HeroBtnWrapper,
  HeroContent,
  HeroH1,
  HeroP,
  ArrowForward,
  ArrowRight
} from './HeroElements';
import Bounce from 'react-reveal/Bounce';

const HeroSection = () => {

  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }


  setTimeout(() => {
  
  }, 2000); 

  return (
    <HeroContainer id='home'>
      <HeroBg>
      <ImgWrap>
          <Img src={img} loading='lazy' />
            </ImgWrap>
      </HeroBg>
      <HeroContent>
        <HeroH1>About Us</HeroH1>
        <HeroP>35 years and Counting...</HeroP>
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
