import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Footer from '../components/Footer';
import VideoSection from '../components/Stories3components/FrontVideo';
import Icon1 from '../images/logo-1.jpeg';
import Icon2 from '../images/stories3/ph1.jpg';
import Icon3 from '../images/stories3/ph2.jpg';
import Icon4 from '../images/stories3/ph3.jpg';
import Icon5 from '../images/stories3/ph4.jpg';
import Icon6 from '../images/stories3/ph5.jpg';
import Icon7 from '../images/stories3/ph6.jpg';
import Icon8 from '../images/stories3/ph7.jpg';
import Icon9 from '../images/stories3/ph8.jpg';
import Icon10 from '../images/stories3/ph9.jpg';
import Icon11 from '../images/stories3/ph10.jpg';
import Icon12 from '../images/stories3/ph11.jpg';
import Icon13 from '../images/stories3/ph12.jpg';
import Icon14 from '../images/stories3/ph13.jpg';
import Icon15 from '../images/stories3/ph14.jpg';
import ResponsiveGallery from 'react-responsive-gallery';

import ScrollToTop from '../components/ScrollToTop';


const StoriesPage3 = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const images=[
   
   
  
    {
      src: Icon3
      },
    {
      src: Icon5
      },
      {
        src: Icon4
        },
         
    {
     src: Icon2
      },

  {
    src: Icon6
    },
  {
    src: Icon8
    },
    {
      src: Icon9
      },
      {
        src: Icon10
        },
        {
          src: Icon11
          },
          {
            src: Icon12
            }, {
              src: Icon13
              }, {
                src: Icon14
                },
                {
                  src: Icon15
                  },
    {
      src: Icon7
      },
  
    ];
  
  
  return (
    <>
             <Sidebar isOpen={isOpen} toggle={toggle}/>
             <ScrollToTop />
      <Navbar toggle = {toggle}/>
      <VideoSection/>

      <FloatingWhatsApp phoneNumber={'9845274189'} avatar={Icon1} statusMessage={'Online'} chatMessage={'Hey!! Welcome..Send a Message for Any Booking or query'} accountName={'R K PHOTO FLASH'}/>

    

<ResponsiveGallery images={images} />
<Footer/>
    </>
  )
}

export default StoriesPage3
