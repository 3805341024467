import React from 'react'



import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    Subtitle1,
    BtnWrap,
    Img,
    ImgWrap,
    NavBtnLink
} from './InfoElements';
import Slide from 'react-reveal/Slide';


const InfoSection = ({
    lightBg,
    id,
    to,
    imgStart,
    lightText,
    headLine,
    darkText,
    description5,    
    buttonLabel,
    img,
    img1,
    alt,
 
}) => {
  return (
   <>
   <InfoContainer lightBg = {lightBg} id={id}>
    <InfoWrapper>
        <InfoRow imgStart = {imgStart}>
            <Column1>
                <TextWrapper>
                    <Heading lightText={lightText}>{headLine}</Heading>            
                    <Subtitle1 darkText={darkText}>{description5}</Subtitle1>
                </TextWrapper>
                <Slide down >
                <ImgWrap>
                    <Img src={img1} alt={alt}/> 
                </ImgWrap>
                </Slide>
                <BtnWrap>
                <NavBtnLink to={to} smooth={true} duration={500} spy={true} exact='true' offset={-80} primary={true} dark={true}>{buttonLabel} </NavBtnLink>
                    </BtnWrap>
            </Column1>
            <Column2>
            <Slide right >
                <ImgWrap>
              
                      <Img src={img} alt={alt} loading='lazy'/>
                </ImgWrap>
                </Slide>
            </Column2>
        </InfoRow>
    </InfoWrapper>
   </InfoContainer>
   </>
  )
}

export default InfoSection
