import { Form } from 'react-router-dom'
import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';


//orange #f57b1d
//dark blue #05051f
//light blue #34399e

export const HeroContainer = styled.div`
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 600px;
    position: relative;
    z-index: 1;


`

export const HeroBg = styled.div`
    position: absolute;
    margin-top: 30px;
    margin-bottom: 150px;
    margin-left: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    overflow: hidden;
    border-radius: 10px;
    border: 15px solid rgba(0,0,0,0.1);

`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #fff;
 
`

