import React from 'react'


import {
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesWrapper,
    ServicesCard,
    ServicesCard1,

    ServicesP
} from './ServicesElements';
import Zoom from 'react-reveal/Zoom';

const Services = () => {
  return (
    <ServicesContainer id="services">
        <ServicesH1>Our Service Packages </ServicesH1>
        <ServicesWrapper>
            <Zoom top duration={700}>
            <ServicesCard to='about'>
                <ServicesH2>Wedding</ServicesH2>
                <ServicesP>Unlimited Pictures </ServicesP>
                <ServicesP>Video coverage Of the full wedding</ServicesP>
                <ServicesP>3 minutes video with song</ServicesP>
                <ServicesCard1 >
                <ServicesP>For Details</ServicesP>
                <ServicesH2>Call us</ServicesH2>
            </ServicesCard1>
            </ServicesCard>
            <ServicesCard>

                <ServicesH2>Outdoor Shoot</ServicesH2>
                 <ServicesP>Unlimited Pictures </ServicesP>
                <ServicesP>Video coverage Of the full program</ServicesP>
                <ServicesP>3 minutes video with song</ServicesP>
                <ServicesCard1 >
                <ServicesP>For Details</ServicesP>
                <ServicesH2>Call us</ServicesH2>
            </ServicesCard1>
            </ServicesCard>
            <ServicesCard>
                <ServicesH2>B'day Party</ServicesH2>
                <ServicesP>Unlimited Pictures </ServicesP>
                <ServicesP>Video coverage Of the full B'day program</ServicesP>
                <ServicesP>3 minutes video with song</ServicesP>
                <ServicesCard1 >
                <ServicesP>For Details</ServicesP>
                <ServicesH2>Call us</ServicesH2>
            </ServicesCard1>
            </ServicesCard>
            <ServicesCard to='about'>
                <ServicesH2>Events</ServicesH2>
                <ServicesP>Unlimited Pictures </ServicesP>
                <ServicesP>Video coverage Of the full program</ServicesP>
                <ServicesP>3 minutes video with song</ServicesP>
                <ServicesCard1>
                <ServicesP>For Details</ServicesP>
                <ServicesH2>Call us</ServicesH2>
            </ServicesCard1>
            </ServicesCard>
            </Zoom>
        </ServicesWrapper>
      
    </ServicesContainer>
  )
}

export default Services
