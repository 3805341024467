import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/Reachoutcomponents/HeroSection'
import InfoSection from '../components/Reachoutcomponents/InfoSection'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Icon1 from '../images/logo-1.jpeg';
import Footer from '../components/Footer';
import { homeObjOne} from '../components/Reachoutcomponents/InfoSection/Data';
import ScrollToTop from '../components/ScrollToTop';

const ReachoutPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
             <Sidebar isOpen={isOpen} toggle={toggle}/>
             <ScrollToTop />
     <Navbar toggle = {toggle}/>
      <HeroSection/>
      <FloatingWhatsApp phoneNumber={'9845274189'} avatar={Icon1} statusMessage={'Online'} chatMessage={'Hey!! Welcome..Send a Message for Any Booking or query'} accountName={'R K PHOTO FLASH'}/>
      <InfoSection {...homeObjOne}/>
      <Footer/>
  


      
    </>
  )
}

export default ReachoutPage
